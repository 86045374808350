import React from "react"

import Navbar from "./navbar"
import Footer from "./footer"
import Content from "./content"

function Layout({ children, menuType }) {
  return (
    <>
      <Navbar menuType={menuType} />
      <Content>{children}</Content>
      <Footer menuType={menuType} />
    </>
  )
}

Layout.defaultProps = {
  menuType: "primary",
}

export default Layout
