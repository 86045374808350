import React from "react"
import MenuContainer from "./menuContainer"
import SubMenuItem from "./subMenuItem"
import * as css from "./mobileMenu.module.css"
import { Link } from "gatsby"
function MobileMenu({ menu }) {
  return (
    <div className={`${css.box} ${menu ? css.menuActive : ""}`}>
      <Link to="/szkola-podstawowa" className={css.link}>
        Strona główna
      </Link>
      <MenuContainer menu={menu} title="Szkoła podstawowa">
        <ul className={css.ul}>
          <li className={css.li}>
            <SubMenuItem to="/szkola-podstawowa/plan-lekcji">
              Plan lekcji
            </SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/szkola-podstawowa/wykaz-podrecznikow">
              Wykaz podręczników
            </SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/szkola-podstawowa/pracownicy">
              Pracownicy szkoły
            </SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/szkola-podstawowa/historia-szkoly">
              Historia szkoły
            </SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/szkola-podstawowa/kalendarz-szkolny">
              Kalendarz szkolny
            </SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/szkola-podstawowa/sponsorzy">
              Sponsorzy
            </SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/szkola-podstawowa/galeria">Galeria</SubMenuItem>
          </li>
        </ul>
      </MenuContainer>
      <MenuContainer menu={menu} title="Dokumenty">
        <ul className={css.ul}>
          <li className={css.li}>
            <SubMenuItem to="/szkola-podstawowa/statut">Statut</SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/szkola-podstawowa/dozywianie">
              Dożywianie
            </SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/szkola-podstawowa/rekrutacja">
              Rekrutacja do szkoły podstawowej
            </SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/szkola-podstawowa/regulaminy-i-procedury">
              Regulaminy i procedury
            </SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/szkola-podstawowa/rodo">RODO</SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/szkola-podstawowa/dokumenty-do-pobrania">
              Dokumenty do pobrania
            </SubMenuItem>
          </li>
        </ul>
      </MenuContainer>
      <MenuContainer menu={menu} title="Projekty">
        <ul className={css.ul}>
          <li className={css.li}>
            <SubMenuItem to="/projekty/lowe">LOWE</SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/projekty/lowe-rekrutacja">
              LOWE Rekrutacja
            </SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/projekty/lowe-oferta">
              LOWE Oferta edukacyjna
            </SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/projekty/lowe-do-pobrania">
              LOWE Do pobrania
            </SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/projekty/grant-3">
              Grant 3
            </SubMenuItem>
          </li>
        </ul>
      </MenuContainer>
      <Link to="/szkola-podstawowa/kontakt" className={css.link}>
        Kontakt
      </Link>
    </div>
  )
}

export default MobileMenu
