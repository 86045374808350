// extracted by mini-css-extract-plugin
export var a = "footer-module--a--4o3Rv";
export var additionalLinks = "footer-module--additionalLinks--++JY1";
export var additional_span = "footer-module--additional_span--lNnya";
export var address = "footer-module--address--GvaNd";
export var box = "footer-module--box--1FcIu";
export var contactContainer = "footer-module--contactContainer--xtNt7";
export var contact_span = "footer-module--contact_span--XAhOZ";
export var h2 = "footer-module--h2--iXvVl";
export var li = "footer-module--li--IsAX6";
export var linksContainer = "footer-module--linksContainer--5Tvtn";
export var links_span = "footer-module--links_span--+dbNH";
export var ul = "footer-module--ul--NqAY3";