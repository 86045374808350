import React from "react"

import * as hamburgerCss from "./hamburger.module.css"

function Hamburger({ menu, toggleMenu }) {
  return (
    <button
      aria-label="Przycisk otwierający i zamykający główne menu"
      className={hamburgerCss.hamburger}
      onClick={() => toggleMenu(!menu)}
    >
      <div
        className={`${hamburgerCss.dash} ${hamburgerCss.red} ${
          menu ? hamburgerCss.top : ""
        }`}
      ></div>
      <div
        className={`${hamburgerCss.dash} ${hamburgerCss.blue} ${
          menu ? hamburgerCss.middle : ""
        }`}
      ></div>
      <div
        className={`${hamburgerCss.dash} ${hamburgerCss.green} ${
          menu ? hamburgerCss.bottom : ""
        }`}
      ></div>
    </button>
  )
}

export default Hamburger
