import React, { useState, useEffect, useRef } from "react"
import Hamburger from "./hamburger"
import * as css from "./navbar.module.css"
import ImageAndTitle from "./imageAndTitle/imageAndTitle"
import MobileMenu from "./mobileMenu"
import KindergardenMenu from "./kindergardenMenu"
import AccessibilityMenu from "./accessibilityMenu"
import SkipLink from "./skipLink"
import FocusTrap from "focus-trap-react"

function Navbar({ menuType }) {
  const [menu, toggleMenu] = useState(false)
  const hamburgerContainer = useRef(null)
  const menuContainer = useRef(null)
  const [focusTrapElements, addFocusTrapElements] = useState([])

  useEffect(() => {
    const body = document.body
    if (menu) {
      body.classList.add(css.noscroll)
    } else {
      body.classList.remove(css.noscroll)
    }

    addFocusTrapElements([hamburgerContainer.current, menuContainer.current])

    return () => {
      body.classList.remove(css.noscroll)
    }
  }, [menu])

  return (
    <nav role="navigation">
      <SkipLink to="#main">Przejdź do zawartości</SkipLink>
      <SkipLink to="#footer">Przejdź do stopki</SkipLink>
      <div className={css.box}>
        <ImageAndTitle />

        <div className={css.hamburgerContainer} ref={hamburgerContainer}>
          <Hamburger menu={menu} toggleMenu={toggleMenu} id="menu" />
        </div>
      </div>
      {menu && (
        <FocusTrap
          containerElements={focusTrapElements}
          focusTrapOptions={{
            escapeDeactivates: false,
          }}
        />
      )}
      <div ref={menuContainer}>
        {menuType === "primary" ? (
          <MobileMenu menu={menu} />
        ) : (
          <KindergardenMenu menu={menu} />
        )}

        <AccessibilityMenu />
      </div>
    </nav>
  )
}

export default Navbar
