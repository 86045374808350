import React, { useState } from "react"
import * as css from "./menuContainer.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

function MenuContainer({ children, menu, title }) {
  const [subMenuActive, toggleSubMenu] = useState(false)

  return (
    <div className={css.subMenuBox}>
      <button
        className={css.button}
        onClick={() => toggleSubMenu(!subMenuActive)}
      >
        {title}{" "}
        <span
          className={`${css.arrow} ${
            subMenuActive && menu ? css.rotateArrow : ""
          }`}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </span>
      </button>
      <div
        className={`${css.subMenu} ${subMenuActive && menu ? css.active : ""}`}
      >
        {children}
      </div>
    </div>
  )
}

export default MenuContainer
