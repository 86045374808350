import React from "react"
import MenuContainer from "./menuContainer"
import SubMenuItem from "./subMenuItem"
import * as css from "./mobileMenu.module.css"
import { Link } from "gatsby"
function KindergardenMenu({ menu }) {
  return (
    <div className={`${css.box} ${menu ? css.menuActive : ""}`}>
      <Link to="/przedszkole" className={css.link}>
        Strona główna
      </Link>
      <Link to="/przedszkole/rekrutacja" className={css.link}>
        Rekrutacja
      </Link>

      <Link to="/przedszkole/pracownicy" className={css.link}>
        Pracownicy
      </Link>

      <Link to="/przedszkole/galeria" className={css.link}>
        Galeria
      </Link>

      <MenuContainer menu={menu} title="Dokumenty">
        <ul className={css.ul}>
          <li className={css.li}>
            <SubMenuItem to="/przedszkole/statut">Statut</SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/przedszkole/dozywianie">Dożywianie</SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/przedszkole/rekrutacja">
              Rekrutacja do przedszkola
            </SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/przedszkole/dokumenty">
              Regulaminy i procedury
            </SubMenuItem>
          </li>
          <li className={css.li}>
            <SubMenuItem to="/przedszkole/rodo">RODO</SubMenuItem>
          </li>
        </ul>
      </MenuContainer>
      <Link to="/przedszkole/kontakt" className={css.link}>
        Kontakt
      </Link>
    </div>
  )
}

export default KindergardenMenu
