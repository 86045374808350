// extracted by mini-css-extract-plugin
export var boldText = "content-module--boldText--RnIl8";
export var centerText = "content-module--centerText--dCsju";
export var container = "content-module--container--TSlr8";
export var content = "content-module--content--VMrOY";
export var justifyText = "content-module--justifyText--g9fLt";
export var links = "content-module--links--j3OA7";
export var longText = "content-module--longText--8+hOC";
export var text = "content-module--text--df8Wp";
export var textBlock = "content-module--textBlock--ZlZtw";
export var underlineText = "content-module--underlineText--jVd+u";