import React from "react"
import PropTypes from "prop-types"
import * as css from "./skipLink.module.css"

function SkipLink({ children, to }) {
  return (
    <a href={to} className={css.skipLink}>
      {children}
    </a>
  )
}

SkipLink.propTypes = {
  to: PropTypes.string.isRequired,
}

export default SkipLink
