import React, { useState, useEffect, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAdjust,
  faLowVision,
  faSearchMinus,
  faSearchPlus,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons"
import * as css from "./accessibilityMenu.module.css"
import FocusTrap from "focus-trap-react"

function AccessibilityMenu() {
  const MAX_FONT_SIZE = 30
  const MIN_FONT_SIZE = 10
  const [accMenu, toggleAccMenu] = useState(false)
  const container = useRef(null)
  const [focusTrapElements, addFocusTrapElements] = useState([])
  const documentGlobal = typeof document !== "undefined" && document
  const html = documentGlobal.documentElement
  useEffect(() => {
    html.classList.add(css.normal)
    addFocusTrapElements([container.current])
  }, [])

  function getNumber(str) {
    // console.log(+str.slice(0, -2))
    return +str.slice(0, -2)
  }

  function sizeUp() {
    if (html.style.fontSize === "") {
      html.style.fontSize = "16px"
    }
    if (getNumber(html.style.fontSize) < MAX_FONT_SIZE) {
      let size = getNumber(html.style.fontSize)
      size += 2
      html.style.fontSize = `${size}px`
    }
  }

  function sizeDown() {
    if (html.style.fontSize === "") {
      html.style.fontSize = "16px"
    }
    if (getNumber(html.style.fontSize) > MIN_FONT_SIZE) {
      let size = getNumber(html.style.fontSize)
      size -= 2
      html.style.fontSize = `${size}px`
    }
  }

  function toggleBlackWhiteContrast() {
    if (html.classList.contains(css.blackWhite)) {
      html.classList.remove(css.blackWhite)
      return
    }
    if (html.classList.contains(css.yellowBlack)) {
      html.classList.remove(css.yellowBlack)
      html.classList.add(css.blackWhite)
    } else {
      html.classList.add(css.blackWhite)
    }
  }

  function toggleBlackYellowContrast() {
    if (html.classList.contains(css.yellowBlack)) {
      html.classList.remove(css.yellowBlack)
      return
    }
    if (html.classList.contains(css.blackWhite)) {
      html.classList.remove(css.blackWhite)
      html.classList.add(css.yellowBlack)
    } else {
      html.classList.add(css.yellowBlack)
    }
  }

  function reset() {
    html.style.fontSize = "16px"
    html.classList = css.normal
  }

  return (
    <div className={`${css.box} ${accMenu ? css.active : ""}`} ref={container}>
      {accMenu && (
        <FocusTrap
          containerElements={focusTrapElements}
          focusTrapOptions={{
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
          }}
        />
      )}
      <button
        aria-label="Przycisk otwierający i zamykający menu dla osób niedowidzących"
        className={css.toggleButton}
        onClick={() => toggleAccMenu(!accMenu)}
      >
        <FontAwesomeIcon icon={faLowVision} />
      </button>
      <div className={css.buttons}>
        <ul>
          <li>
            <button onClick={() => sizeUp()}>
              <FontAwesomeIcon icon={faSearchPlus} />
              Powiększ czcionkę
            </button>
          </li>
          <li>
            <button onClick={() => sizeDown()}>
              <FontAwesomeIcon icon={faSearchMinus} />
              Pomniejsz czcionkę
            </button>
          </li>
          <li>
            <button onClick={() => toggleBlackWhiteContrast()}>
              <FontAwesomeIcon icon={faAdjust} />
              Kontrast czarno-biały
            </button>
          </li>
          <li>
            <button onClick={() => toggleBlackYellowContrast()}>
              <FontAwesomeIcon icon={faAdjust} className={css.yellow} />
              Kontrast żółto-czarny
            </button>
          </li>
          <li>
            <button onClick={() => reset()}>
              <FontAwesomeIcon icon={faUndoAlt} />
              Reset
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default AccessibilityMenu
