import React from "react"
import * as css from "./content.module.css"

function Content({ children }) {
  return (
    <main className={`container ${css.container}`} id="main">
      <div className={css.content}>{children}</div>
    </main>
  )
}

export default Content
