import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Link } from "gatsby"
import * as css from "./footer.module.css"

function Footer({ menuType }) {
  const declarationLink =
    menuType === "primary"
      ? "/szkola-podstawowa/deklaracja-dostepnosci"
      : "/przedszkole/deklaracja-dostepnosci"

  return (
    <footer className={css.box} id="footer">
      <div className={css.linksContainer}>
        <h2 className={css.h2}>
          <span className={css.links_span}>Przydatne Linki</span>
        </h2>
        <ul className={css.ul}>
          <li className={css.li}>
            <Link to={declarationLink} className={css.a}>
              Deklaracja dostępności
            </Link>
          </li>
          <li className={css.li}>
            <a
              href="https://uonetplus.vulcan.net.pl/gminawieprz"
              className={css.a}
            >
              Dziennik elektroniczny
            </a>
          </li>
          <li className={css.li}>
            <a href="http://mail.google.com/a/zspnidek.pl" className={css.a}>
              Platforma G Suite
            </a>
          </li>
        </ul>
      </div>
      <div className={css.contactContainer}>
        <h2 className={css.h2}>
          <span className={css.contact_span}>Kontakt</span>
        </h2>
        <ul className={css.ul}>
          <li className={css.li}>
            <address className={css.address}>
              Nidek, ul. Św. Judy Tadeusza 2
            </address>
          </li>

          <li className={css.li}>
            <a href="tel:338755811" className={css.a}>
              33 875-58-11
            </a>
          </li>
          <li className={css.li}>
            <a href="mailto:sekretariat@zspnidek.wieprz.pl" className={css.a}>
              sekretariat@zspnidek.wieprz.pl
            </a>
          </li>
        </ul>
        <div>
          <StaticImage
            src="../images/fundusze.jpg"
            alt=""
            width={203}
            height={200}
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
            <StaticImage
              src="../images/aspe_logo.svg"
              alt=""
              width={150}
              height={80}
            />
            <StaticImage
              src="../images/laboratoria-logo.png"
              alt=""
              width={180}
              height={80}
            />
            <StaticImage
              src="../images/poznajpolske.jpeg"
              alt=""
              width={200}
              height={80}
            />
          </div>
        </div>
      </div>
      <div>
        <h2 className={css.h2}>
          <span className={css.additional_span}>Dodatkowe Informacje</span>
        </h2>
        <div className={css.additionalLinks}>
          <a href="http://www.wieprz.pl/" className={css.a}>
            <StaticImage
              src="../images/logo_gmina_wieprz.png"
              alt="Logo Gminy Wieprz"
              width={200}
            />
          </a>
          <a href="https://bip.malopolska.pl/zsimknidku" className={css.a}>
            <StaticImage src="../images/bip.jpg" alt="Logo BIP" width={200} />
          </a>

          <a href="https://www.gov.pl/web/edukacja-i-nauka" className={css.a}>
            <StaticImage
              src="../images/logo_men.png"
              alt="Logo MEN"
              width={200}
            />
          </a>
          <a href="https://kuratorium.krakow.pl/" className={css.a}>
            <StaticImage
              src="../images/logo_kuratorium.jpg"
              alt="Logo Kuratorium w Krakowie"
              width={103}
              height={103}
            />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
